import * as WS from 'isomorphic-ws';
import { WebSocket } from './websocket';
import {
    SignalRoomState,
    SignalVoteState,
    SignalType,
    BaseSignal,
    SignalParticipantUpdate,
    SignalParticipantUnregister,
    SignalContentStreamMetadata,
    SignalContentState,
    SignalVoteStreakState,
    SignalVoteCast,
    SignalParticipantName,
    SignalParticipantCamMic,
    SignalParticipantAuth,
    SignalError,
    SignalStageInvite,
    SignalStageKick,
    SignalStageRSVP,
    SignalStageUpdate,
    SignalStageRequest,
    SignalStageAccept,
    SignalRoomMutedUpdate,
    SignalParticipantMute,
    SignalRoomSettingsUpdate,
    SignalSubsQueueSizeUpdate,
    SignalRoomSession,
} from './signal';
import { Participant as ParticipantType } from './typings';
import nanoid from '../nanoid';
import {
    CamMicState,
    CamMode,
    ParticipantParams,
    ParticipantUser,
    SignalParticipantCam,
    SignalParticipantMic,
    SignalParticipantsUpdate,
    SignalQualityIndicator,
} from '.';
import base64 from 'base-64';

export interface IParticipantHandler {
    onopen: (reconnect: boolean) => void;
    ondisconnect: () => void;
    onRoomState: (s: SignalRoomState) => void;
    onRoomSettingsUpdate: (s: SignalRoomSettingsUpdate) => void;
    onRoomSessionUpdate: (s: SignalRoomSession) => void;
    onSubsQueueSizeUpdate: (s: SignalSubsQueueSizeUpdate) => void;
    onRoomMutedUpdate: (s: SignalRoomMutedUpdate) => void;
    onParticipantMute: (s: SignalParticipantMute) => void;
    onRemoteParticipantUnregister: (s: SignalParticipantUnregister) => void;
    onRemoteParticipantUpdate: (s: SignalParticipantUpdate) => void;
    onRemoteParticipantsUpdate: (s: SignalParticipantsUpdate) => void;
    onContentState: (s: SignalContentState) => void;
    onContentStreamMetadata: (s: SignalContentStreamMetadata) => void;
    onVoteState: (s: SignalVoteState) => void;
    onVoteStreakState: (s: SignalVoteStreakState) => void;
    onAuthError?: (error: string) => void;
    onAPIRoomRefresh: () => void;
    onStageUpdate: (s: SignalStageUpdate) => void;
    onStageInvite: (s: SignalStageInvite) => void;
    onStageKick: (s: SignalStageKick) => void;
    onStageRSVP: (s: SignalStageRSVP) => void;
    onStageRequest: (s: SignalStageRequest) => void;
    onStageAccept: (s: SignalStageAccept) => void;
    onQualityIndicator: (s: SignalQualityIndicator) => void;
    onNoRetry: () => void;
    shouldRetry: () => Promise<boolean>;
}

export interface IParticipantConfig {
    roomID: string;
    wsURI: string;
    accessToken: string;
    handler: IParticipantHandler;
    user: ParticipantUser;
    guestID?: string;
    bypassQueue?: boolean;
    ghost?: boolean;
    bypassPreview?: boolean;
    mockUser?: boolean;
    turnServer?: number;
}

export class Participant {
    public id: string;
    protected _participant: ParticipantType;
    protected _roomID: string;
    protected _ws: WebSocket;
    private _handler: IParticipantHandler;

    constructor(config: IParticipantConfig) {
        const {
            roomID,
            wsURI,
            accessToken,
            handler,
            user,
            guestID = '',
            bypassQueue = false,
            ghost = false,
            bypassPreview = false,
            mockUser = false,
        } = config;

        this.id = nanoid();
        this._participant = {
            id: this.id,
            dupeUser: false,
            userID: '',
            name: user.name,
            camState: {
                state: CamMicState.Disabled,
                mode: CamMode.Standard,
                timestamp: 0,
            },
            micState: {
                state: CamMicState.Disabled,
                timestamp: 0,
            },
            permissions: [],
            connectedAt: 0,
            queuePos: 0,
            previewTimer: -1,
            ghost: false,
        };
        this._handler = handler;
        this._roomID = roomID;

        const params: ParticipantParams = {
            ...user,
            id: this.id,
            guestID,
            bypassQueue,
            ghost,
            bypassPreview,
            mockUser,
        };

        const jsonParams = JSON.stringify(params);
        const uriComponent = encodeURIComponent(jsonParams);
        const b64URIEncodedParams = base64.encode(uriComponent);
        const wsQS = `?participantParamsV2=${b64URIEncodedParams}`;
        this._ws = new WebSocket(`${wsURI}${wsQS}`, accessToken);
        // this._ws = new WebSocket(`ws://localhost:8080/signal${wsQS}`, accessToken);
        this._addSignalListeners();
    }

    private _addSignalListeners() {
        this._ws.onopen = this._handleOpen.bind(this);
        this._ws.ondisconnect = this._handleDisconnect.bind(this);
        this._ws.shouldRetry = this._handler.shouldRetry;
        this._ws.onNoRetry = this._handler.onNoRetry;
        this._ws.addMessageListener(this._handleSocketMessage.bind(this));
    }

    private _updateParticipant = (updated: ParticipantType) => {
        this._participant.userID = updated.userID;
        this._participant.name = updated.name;
        this._participant.micState = {
            state: updated.micState.state,
            timestamp: updated.micState.timestamp,
        };
        this._participant.camState = {
            state: updated.camState.state,
            mode: updated.camState.mode,
            timestamp: updated.camState.timestamp,
        };
        this._participant.permissions = updated.permissions;
        this._participant.dupeUser = updated.dupeUser;
        this._participant.ghost = updated.ghost;
        this._participant.connectedAt = updated.connectedAt;
        this._participant.queuePos = updated.queuePos;
    };

    private _handleSocketMessage(event: WS.MessageEvent) {
        const s: BaseSignal = JSON.parse(<string>event.data);
        switch (s.type) {
            case SignalType.RoomState:
                this._handler.onRoomState(<SignalRoomState>s);
                break;
            case SignalType.RoomSettingsUpdate:
                this._handler.onRoomSettingsUpdate(<SignalRoomSettingsUpdate>s);
                break;
            case SignalType.RoomSessionUpdate:
                this._handler.onRoomSessionUpdate(<SignalRoomSession>s);
                break;
            case SignalType.ParticipantUnregister:
                this._handler.onRemoteParticipantUnregister(<SignalParticipantUnregister>s);
                break;
            case SignalType.ParticipantUpdate:
                const sp = <SignalParticipantUpdate>s;
                if (this._participant.id == sp.participant.id) {
                    this._updateParticipant(sp.participant);
                }
                this._handler.onRemoteParticipantUpdate(sp);
                break;
            case SignalType.ParticipantsUpdate:
                const spu = <SignalParticipantsUpdate>s;
                const updated = spu.participants.find((p) => p.id === this._participant.id);
                if (updated) {
                    this._updateParticipant(updated);
                }
                this._handler.onRemoteParticipantsUpdate(spu);
                break;
            case SignalType.ContentStreamState:
                this._handler.onContentState(<SignalContentState>s);
                break;
            case SignalType.ContentStreamMetadata:
                this._handler.onContentStreamMetadata(<SignalContentStreamMetadata>s);
                break;
            case SignalType.VoteState:
                this._handler.onVoteState(<SignalVoteState>s);
                break;
            case SignalType.VoteStreakState:
                this._handler.onVoteStreakState(<SignalVoteStreakState>s);
                break;
            case SignalType.AuthError:
                const se = <SignalError>s;
                if (this._handler.onAuthError) {
                    this._handler.onAuthError(se.error);
                }
                break;
            case SignalType.APIRoomRefresh:
                this._handler.onAPIRoomRefresh();
                break;
            case SignalType.RoomMutedUpdate:
                this._handler.onRoomMutedUpdate(<SignalRoomMutedUpdate>s);
                break;
            case SignalType.SubsQueueSizeUpdate:
                this._handler.onSubsQueueSizeUpdate(<SignalSubsQueueSizeUpdate>s);
                break;
            case SignalType.ParticipantMute:
                this._handler.onParticipantMute(<SignalParticipantMute>s);
                break;
            case SignalType.StageUpdate:
                this._handler.onStageUpdate(<SignalStageUpdate>s);
                break;
            case SignalType.StageInvite:
                this._handler.onStageInvite(<SignalStageInvite>s);
                break;
            case SignalType.StageKick:
                this._handler.onStageKick(<SignalStageKick>s);
                break;
            case SignalType.StageRSVP:
                this._handler.onStageRSVP(<SignalStageRSVP>s);
                break;
            case SignalType.StageRequest:
                this._handler.onStageRequest(<SignalStageRequest>s);
                break;
            case SignalType.StageAccept:
                this._handler.onStageAccept(<SignalStageAccept>s);
                break;
            case SignalType.QualityIndicator:
                this._handler.onQualityIndicator(<SignalQualityIndicator>s);
                break;
            default:
        }
    }

    private _handleOpen(reconnect: boolean) {
        this._handler.onopen(reconnect);
    }

    private _handleDisconnect() {
        this._handler.ondisconnect();
    }

    auth(userID: string, accessToken: string, user: ParticipantUser) {
        const s: SignalParticipantAuth = {
            type: SignalType.ParticipantAuth,
            userID,
            accessToken,
            ...user,
        };
        this._ws.send(s);
    }

    updateName(name: string) {
        const s: SignalParticipantName = {
            type: SignalType.ParticipantName,
            name: name,
        };
        this._ws.send(s);
    }

    vote(receiverID: string, up: boolean) {
        const s: SignalVoteCast = {
            type: SignalType.VoteCast,
            senderID: this._participant.id,
            receiverID: receiverID,
            up: up,
        };

        this._ws.send(s);
    }

    setCameraState(state: CamMicState, mode: CamMode) {
        const s: SignalParticipantCam = {
            type: SignalType.ParticipantCam,
            state,
            mode,
        };
        this._ws.send(s);
    }

    setMicState(state: CamMicState) {
        const s: SignalParticipantMic = {
            type: SignalType.ParticipantMic,
            state,
        };
        this._ws.send(s);
    }

    dupeUser() {
        return this._participant.dupeUser;
    }

    ghost() {
        return this._participant.ghost;
    }

    refreshAPIRoom() {
        const s: BaseSignal = {
            type: SignalType.APIRoomRefresh,
        };
        this._ws.send(s);
    }

    // isMod() {
    //     const modPerms = [
    //         PermissionType.Speak,
    //         PermissionType.Stream,
    //         PermissionType.StageInvite,
    //         PermissionType.Ban,
    //     ];
    //     const participantPermissions = this._participant.permissions.map((perm) =>
    //         perm.enabled ? perm.permission : null
    //     );
    //     return modPerms.every((p) => participantPermissions.includes(p));
    // }

    mute(mute: boolean, userID: string) {
        const s: SignalParticipantMute = {
            type: SignalType.ParticipantMute,
            mutedID: userID,
            muterID: this._participant.userID,
            mute: mute,
        };
        this._ws.send(s);
    }

    inviteToStage(inviteeID: string) {
        const s: SignalStageInvite = {
            type: SignalType.StageInvite,
            inviteeID: inviteeID,
            revoke: false,
        };
        this._ws.send(s);
    }

    revokeStageInvite(inviteeID: string) {
        const s: SignalStageInvite = {
            type: SignalType.StageInvite,
            inviteeID: inviteeID,
            revoke: true,
        };
        this._ws.send(s);
    }

    rsvpToStage(accept: boolean) {
        const s: SignalStageRSVP = {
            type: SignalType.StageRSVP,
            accepted: accept,
        };
        this._ws.send(s);
    }

    kickFromStage(kickedID: string) {
        const s: SignalStageKick = {
            type: SignalType.StageKick,
            kickedID: kickedID,
            kickerID: this._participant.userID,
        };
        this._ws.send(s);
    }

    requestToJoinStage() {
        const s: SignalStageRequest = {
            type: SignalType.StageRequest,
            revoke: false,
        };
        this._ws.send(s);
    }

    revokeStageRequest() {
        const s: SignalStageRequest = {
            type: SignalType.StageRequest,
            revoke: true,
        };
        this._ws.send(s);
    }

    respondToStageRequest(accept: boolean, requesterID: string) {
        const s: SignalStageAccept = {
            type: SignalType.StageAccept,
            accepted: accept,
            requesterID: requesterID,
        };
        this._ws.send(s);
    }

    close(unregister = true) {
        if (unregister) {
            const s: SignalParticipantUnregister = {
                type: SignalType.ParticipantUnregister,
                participantID: this._participant.id,
            };
            this._ws.send(s);
        }

        this._ws.close();
    }

    get queuePos() {
        return this._participant.queuePos;
    }
}
