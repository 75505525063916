import WS from 'isomorphic-ws';

import { BaseSignal, SignalType } from './client';

const MAX_WS_RECONN_BACKOFF_MS: number = 32000; // 32 seconds
const MAX_WS_RECONN_TIME_MS: number = 2 * 60 * 1000; // 2 minutes

class WebSocket {
    protected socket: WS;
    private _onopen?: (reconnect: boolean) => void;
    private _onerror?: (error: WS.ErrorEvent) => void;
    private _ondisconnect?: (retrying: boolean) => void;
    private _connRetries: number;
    private _disconnectStart: number;
    private _heartbeatInterval: NodeJS.Timeout | null;
    private _reconnect: boolean;
    private _retryTimeout: NodeJS.Timeout | null;
    private _uri: string;
    private _accessToken: string;
    private _pendingMessages: any[];
    _messageListeners: ((event: WS.MessageEvent) => void)[];

    constructor(uri: string, accessToken: string) {
        this._connRetries = 0;
        this._disconnectStart = 0;
        this._reconnect = false;
        this._uri = uri;
        this._heartbeatInterval = null;
        this._retryTimeout = null;
        this._accessToken = accessToken;
        let wsProtos: string[] = [];
        if (this._accessToken) {
            wsProtos = [`Bearer%${this._accessToken}`];
        }
        this.socket = new WS(uri, wsProtos);
        this._messageListeners = [];
        this._pendingMessages = [];
        this._setupEventListeners();
    }

    private _setupEventListeners() {
        this.socket.addEventListener('open', this._handleSocketOpen);
        this.socket.addEventListener('error', this._handleSocketError);
        this.socket.addEventListener('close', this._handleSocketClose);
    }

    private _removeEventListeners() {
        this.socket.removeEventListener('open', this._handleSocketOpen);
        this.socket.removeEventListener('error', this._handleSocketError);
        this.socket.removeEventListener('close', this._handleSocketClose);
        this._messageListeners.forEach((onmessage) => {
            this.socket.removeEventListener('message', onmessage);
        });
    }

    private _reinitWebSocket(uri: string) {
        this._removeEventListeners();
        let wsProtos: string[] = [];
        if (this._accessToken) {
            wsProtos = [`Bearer%${this._accessToken}`];
        }
        this.socket = new WS(uri, wsProtos);
        this._messageListeners.forEach((onmessage) => {
            this.socket.addEventListener('message', onmessage);
        });
        this._reconnect = true;
        this._uri = uri;
        this._setupEventListeners();
    }

    private _handleSocketOpen = () => {
        this._connRetries = 0;
        this._disconnectStart = 0;
        this._setupHeartbeat();
        // console.log('sending ' + this._pendingMessages.length + ' pending messages');
        this._pendingMessages.forEach((s) => {
            this.send(s);
        });
        this._pendingMessages = [];

        if (this._onopen) this._onopen(this._reconnect);
    };

    private _handleSocketError = (e: any) => {
        if (this._onerror) this._onerror(e);
    };

    private _handleSocketClose = (e: any) => {
        console.log('_handleSocketClose');
        this._clearHeartbeat();

        if (this._disconnectStart) {
            const now = new Date();
            if (now.getTime() - this._disconnectStart >= MAX_WS_RECONN_TIME_MS) {
                console.log('final disconnect');
                if (this._ondisconnect) this._ondisconnect(false);
                this._disconnectStart = 0;
                this._connRetries = 0;
                this._reconnect = false;
                return;
            }
        } else {
            this._disconnectStart = new Date().getTime();
        }

        const jitter = Math.floor(Math.random() * 1000);
        const backoff = Math.min(2 ** this._connRetries + jitter, MAX_WS_RECONN_BACKOFF_MS);
        this._connRetries++;
        this._retryTimeout = setTimeout(() => {
            if (this._ondisconnect) this._ondisconnect(true);
            this._reinitWebSocket(this._uri);
        }, backoff);
        return;
    };

    addMessageListener(onmessage: (event: WS.MessageEvent) => void) {
        this._messageListeners.push(onmessage);
        this.socket.addEventListener('message', onmessage);
    }

    removeMessageListener(onmessage: (event: WS.MessageEvent) => void) {
        this.socket.removeEventListener('message', onmessage);
    }

    private _clearHeartbeat() {
        if (this._heartbeatInterval) {
            clearInterval(this._heartbeatInterval);
        }
    }

    private _setupHeartbeat() {
        this._heartbeatInterval = setInterval(() => {
            if (!this.socket) {
                console.error('no socket for heartbeat');
                return;
            }
            const s: BaseSignal = {
                type: SignalType.Heartbeat,
            };
            this.send(s);
        }, 3000);
    }

    send(s: BaseSignal) {
        if (this.socket.readyState !== WS.OPEN) {
            // console.log(
            //     'send called in socket non open readystate',
            //     s.type,
            //     this.socket.readyState
            // );
            this._pendingMessages.push(s);
            return;
        }

        // console.log('sending message', s.type);
        this.socket.send(JSON.stringify(s));
    }

    close() {
        this._clearHeartbeat();
        if (this._retryTimeout) {
            clearTimeout(this._retryTimeout);
        }
        this._removeEventListeners();
        this.socket.close();
    }

    set onopen(onopen: (reconnect: boolean) => void) {
        if (this.socket.readyState === WS.OPEN) {
            onopen(false);
        }
        this._onopen = onopen;
    }

    set onerror(onerror: (error: WS.ErrorEvent) => void) {
        this._onerror = onerror;
    }

    set ondisconnect(ondisconnect: (retrying: boolean) => void) {
        this._ondisconnect = ondisconnect;
    }
}

export { WebSocket };
