import * as WS from 'isomorphic-ws';
import base64 from 'base-64';
import nanoid from './nanoid';
import { AddonTypes, Platforms, SubscriptionTypes } from './platforms';
import { WebSocket } from './websocket';

export const DEFAULT_PORTAL_SCALE_FACTOR = 2;

export enum SignalType {
    Heartbeat = 0,
    Init = 1,
    Auth = 2,
    AuthSuccess = 3,
    AuthFail = 4,
    AuthPortal = 5,
    AuthInvalid = 6,
    AuthUsernameInvalid = 7,
    AuthPasswordInvalid = 8,
    AuthPortalTimeout = 9,
    Subscription = 10,
    Unauthorized = 11,
    SubscriptionFail = 12,
    InFlightStatus = 13,
    KeyboardShow = 14,
    KeyboardHide = 15,
    ClipboardPaste = 16,
    KeyboardInput = 17,
    AbortConnection = 18,
    AuthPlatformError = 19,
}

export enum InFlightState {
    Authenticating = 1,
    Portal = 2,
    SubParsing = 3,
}

export type InFlightStatus = {
    platform: Platforms;
    username: string;
    state: InFlightState;
    portalID?: string;
    portalViewport?: { width: number; height: number; deviceScaleFactor: number };
    activeElement: boolean;
};

export interface BaseSignal {
    type: SignalType;
}

export interface InitSignal extends BaseSignal {}

export interface AuthSignal extends BaseSignal {
    platform: Platforms;
    userID: string;
    username: string;
    password: string;
}

export interface AuthSuccessSignal extends BaseSignal {
    platform: Platforms;
    screenshot?: string | Buffer;
}
export interface AuthFailSignal extends BaseSignal {
    platform: Platforms;
    message?: string;
    screenshot?: string | Buffer;
}

export interface AuthPlatformErrorSignal extends BaseSignal {
    platform: Platforms;
    message?: string;
}
export interface AuthPortalSignal extends BaseSignal {
    platform: Platforms;
    portalID: string;
}

export interface AuthInvalidSignal extends BaseSignal {
    platform: Platforms;
}

export interface AuthUsernameInvalidSignal extends BaseSignal {
    platform: Platforms;
    username: string;
}

export interface AuthPasswordInvalidSignal extends BaseSignal {
    platform: Platforms;
}

export interface AuthPortalTimeoutSignal extends BaseSignal {
    platform: Platforms;
}

export interface UnauthorizedSignal extends BaseSignal {}

export interface SubscriptionSignal extends BaseSignal {
    platform: Platforms;
    subType: SubscriptionTypes;
    addons: AddonTypes[];
    location: string;
    username: string;
}

export interface SubscriptionFailSignal extends BaseSignal {
    platform: Platforms;
}

export interface InFlightStatusSignal extends BaseSignal {
    status: InFlightStatus;
}

export interface KeyboardShowSignal extends BaseSignal {}

export interface KeyboardHideSignal extends BaseSignal {}

export interface ClipboardPasteSignal extends BaseSignal {
    connectionID: string;
    data: string;
}

export interface KeyboardInputSignal extends BaseSignal {
    connectionID: string;
    data: string;
}

export interface AbortConnectionSignal extends BaseSignal {}

export interface IClientHandler {
    onopen: (reconnect: boolean) => void;
    ondisconnect: (retrying: boolean) => void;
    onInit: (signal: InitSignal) => void;
    onAuthSuccess: (signal: AuthSuccessSignal) => void;
    onAuthPortal: (signal: AuthPortalSignal) => void;
    onAuthPortalTimeout: (signal: AuthPortalTimeoutSignal) => void;
    onAuthPlatformError: (signal: AuthPlatformErrorSignal) => void;
    onAuthFail: (signal: AuthFailSignal) => void;
    onAuthUsernameInvalid: (signal: AuthUsernameInvalidSignal) => void;
    onAuthPasswordInvalid: (signal: AuthPasswordInvalidSignal) => void;
    onAuthInvalid: (signal: AuthPasswordInvalidSignal) => void;
    onUnauthorized: (signal: UnauthorizedSignal) => void;
    onSubscription: (signal: SubscriptionSignal) => void;
    onSubscriptionFail: (signal: SubscriptionFailSignal) => void;
    onInFlightStatus: (signal: InFlightStatusSignal) => void;
    onKeyboardShow: (signal: KeyboardShowSignal) => void;
    onKeyboardHide: (signal: KeyboardHideSignal) => void;
}

export interface IClientParams {
    wsURI: string;
    accessToken?: string;
    handler: IClientHandler;
    viewport: {
        width: number;
        height: number;
        deviceScaleFactor: number;
        // isMobile: boolean;
        // hasTouch: boolean;
        // isLandscape: boolean;
    };
    connectionID: string;
    roomID?: string;
}

export interface IConnectionParams {
    id: string;
    viewport: {
        width: number;
        height: number;
        deviceScaleFactor: number;
        // isMobile: boolean;
        // hasTouch: boolean;
        // isLandscape: boolean;
    };
    connectionID: string;
    roomID?: string;
}

export class Client {
    private _id: string;
    protected _ws: WebSocket;
    private _handler: IClientHandler;
    private _connectionID: string;

    constructor(config: IClientParams) {
        const { wsURI, handler } = config;

        this._id = nanoid();

        this._handler = handler;

        const params: IConnectionParams = {
            id: this._id,
            viewport: config.viewport,
            connectionID: config.connectionID,
            roomID: config.roomID,
        };

        this._connectionID = config.connectionID;

        const jsonParams = JSON.stringify(params);
        const b64EncodedParams = base64.encode(jsonParams);
        const wsQS = `?params=${b64EncodedParams}`;
        let accessToken = '';
        if (config.accessToken) {
            accessToken = config.accessToken;
        }
        this._ws = new WebSocket(`${wsURI}${wsQS}`, accessToken);

        this._addSignalListeners();
    }

    private _addSignalListeners() {
        this._ws.onopen = this._handleOpen.bind(this);
        this._ws.ondisconnect = this._handleDisconnect.bind(this);
        this._ws.addMessageListener(this._handleMessage.bind(this));
    }

    private _handleMessage(event: WS.MessageEvent) {
        const s: BaseSignal = JSON.parse(<string>event.data);
        switch (s.type) {
            case SignalType.Init:
                this._handler.onInit(s as InitSignal);
                break;
            case SignalType.AuthSuccess:
                this._handler.onAuthSuccess(s as AuthSuccessSignal);
                break;
            case SignalType.AuthPortal:
                this._handler.onAuthPortal(s as AuthPortalSignal);
                break;
            case SignalType.AuthPortalTimeout:
                this._handler.onAuthPortalTimeout(s as AuthPortalTimeoutSignal);
                break;
            case SignalType.AuthPlatformError:
                this._handler.onAuthPlatformError(s as AuthPlatformErrorSignal);
                break;
            case SignalType.AuthFail:
                this._handler.onAuthFail(s as AuthFailSignal);
                break;
            case SignalType.AuthUsernameInvalid:
                this._handler.onAuthUsernameInvalid(s as AuthUsernameInvalidSignal);
                break;
            case SignalType.AuthPasswordInvalid:
                this._handler.onAuthPasswordInvalid(s as AuthPasswordInvalidSignal);
                break;
            case SignalType.AuthInvalid:
                this._handler.onAuthInvalid(s as AuthInvalidSignal);
                break;
            case SignalType.Unauthorized:
                this._handler.onUnauthorized(s as UnauthorizedSignal);
                break;
            case SignalType.Subscription:
                this._handler.onSubscription(s as SubscriptionSignal);
                break;
            case SignalType.SubscriptionFail:
                this._handler.onSubscriptionFail(s as SubscriptionFailSignal);
                break;
            case SignalType.InFlightStatus:
                this._handler.onInFlightStatus(s as InFlightStatusSignal);
                break;
            case SignalType.KeyboardShow:
                this._handler.onKeyboardShow(s as KeyboardShowSignal);
                break;
            case SignalType.KeyboardHide:
                this._handler.onKeyboardHide(s as KeyboardHideSignal);
                break;
            default:
        }
    }

    private _handleOpen(reconnect: boolean) {
        this._handler.onopen(reconnect);
    }

    private _handleDisconnect(retrying: boolean) {
        this._handler.ondisconnect(retrying);
    }

    get connectionID() {
        return this._connectionID;
    }

    auth(userID: string, username: string, password: string, platform: Platforms) {
        const s: AuthSignal = {
            type: SignalType.Auth,
            userID,
            platform,
            username,
            password,
        };
        this._ws.send(s);
    }

    clipboardPaste(text: string) {
        const s: ClipboardPasteSignal = {
            type: SignalType.ClipboardPaste,
            data: text,
            connectionID: this.connectionID,
        };
        this._ws.send(s);
    }

    keyboardInput(key: string) {
        const s: KeyboardInputSignal = {
            type: SignalType.KeyboardInput,
            data: key,
            connectionID: this.connectionID,
        };
        this._ws.send(s);
    }

    abort() {
        const s: AbortConnectionSignal = {
            type: SignalType.AbortConnection,
        };
        this._ws.send(s);
        this.close();
    }

    close() {
        this._ws.close();
    }
}
