import { Configuration } from '@playback-sports/ion-sdk-js/lib/client';

export type CapturableElement = HTMLMediaElement & {
    captureStream: () => MediaStream;
};

const TURN_SERVERS = [
    'turn:coturn.getplayback.dev:3478',
    'turn:coturn-1.getplayback.dev:3478',
    'turn:coturn-2.getplayback.dev:3478',
    'turn:coturn-3.getplayback.dev:3478',
];

function getRandomTurnServer() {
    const index = Math.floor(Math.random() * TURN_SERVERS.length);
    return TURN_SERVERS[index];
}

export const ION_CLIENT_CONFIG: any = {
    iceServers: [
        {
            urls: getRandomTurnServer(),
            username: 'admin',
            credentialType: 'password',
            credential: 'qDYbEBHyBZQ3HkFah7jetuDE',
        },
    ],
    // iceServers: [
    //     {
    //         urls: 'stun:us-turn3.xirsys.com',
    //     },
    //     {
    //         username:
    //             'iEn2H4bi7qX3Uh02FJlXXAO9daA1VKFLBcPIiu8TR1XRKIDgk93vqdhtDEi2hbmnAAAAAGDehC50YWIxMjkz',
    //         credential: '5f878146-dae3-11eb-851b-0242ac140004',
    //         credentialType: 'password',
    //         urls: [
    //             'turn:us-turn3.xirsys.com:80?transport=udp',
    //             'turn:us-turn3.xirsys.com:3478?transport=udp',
    //             'turn:us-turn3.xirsys.com:80?transport=tcp',
    //             'turn:us-turn3.xirsys.com:3478?transport=tcp',
    //             'turns:us-turn3.xirsys.com:443?transport=tcp',
    //             'turns:us-turn3.xirsys.com:5349?transport=tcp',
    //         ],
    //     },
    // ],
    codec: 'h264',
    // Force unified plan
    sdpSemantics: 'unified-plan',
};

export const generateIonClientConfig = (
    turnServer: number | undefined = undefined
): Configuration & { sdpSemantics: string } => {
    let urls: string | string[] = getRandomTurnServer();
    if (turnServer !== undefined && turnServer >= 0 && turnServer < TURN_SERVERS.length) {
        urls = TURN_SERVERS[turnServer];
    }

    return {
        iceServers: [
            // {
            //     urls: 'stun:coturn.getplayback.dev:3478',
            // },
            {
                urls,
                username: 'admin',
                credentialType: 'password',
                credential: 'qDYbEBHyBZQ3HkFah7jetuDE',
            },
        ],
        codec: 'h264',
        sdpSemantics: 'unified-plan',
        iceTransportPolicy: 'relay',
    };
};
