import { RoomSettings, ScheduledSession } from '@internal/api/types';
import { CamMicState, CamMode, QualityIndicatorType } from '.';
import { PublisherWebRTCMetricsUpdate, SubscriberWebRTCMetricsUpdate } from './metrics';
import {
    ContentState,
    ContentStreamMetadata,
    JoinConfig,
    Participant,
    RoomState,
    Vote,
    VoteState,
    VoteStreakState,
} from './typings';

export enum SignalType {
    Heartbeat = 'heartbeat',

    PeerCreate = 'peerCreate',
    PeerClose = 'peerClose',
    PeerJoin = 'peerJoin',
    PeerOffer = 'peerOffer',
    PeerAnswer = 'peerAnswer',
    PeerTrickle = 'peerTrickle',

    RoomState = 'roomState',
    RoomSettingsUpdate = 'roomSettingsUpdate',
    RoomMutedUpdate = 'roomMutedUpdate',
    SubsQueueSizeUpdate = 'subsQueueSizeUpdate',

    ParticipantUnregister = 'participantUnregister',
    ParticipantName = 'participantName',
    ParticipantCamMic = 'participantCamMic',
    ParticipantCam = 'participantCam',
    ParticipantMic = 'participantMic',
    ParticipantUpdate = 'participantUpdate',
    ParticipantsUpdate = 'participantsUpdate',
    ParticipantAuth = 'participantAuth',
    ParticipantMute = 'participantMute',

    ContentStreamMetadata = 'contentStreamMetadata',
    ContentStreamState = 'contentStreamState',

    ContentStreamPublisherMetrics = 'contentStreamPublisherMetrics',
    ContentStreamSubscriberMetrics = 'contentStreamSubscriberMetrics',

    VoteCast = 'voteCast',
    VoteState = 'voteState',
    VoteStreakState = 'voteStreakState',

    StageUpdate = 'stageUpdate',
    StageInvite = 'stageInvite',
    StageRSVP = 'stageRSVP',
    StageKick = 'stageKick',
    StageRequest = 'stageRequest',
    StageAccept = 'stageAccept',

    QualityIndicator = 'qualityIndicator',

    AuthError = 'authError',

    APIRoomRefresh = 'apiRoomRefresh',

    RoomSessionUpdate = 'roomSessionUpdate',
}

export interface BaseSignal {
    type: SignalType;
}

export interface SignalError {
    type: SignalType;
    error: string;
}

export interface IdentifiedSignal {
    type: SignalType;
    id: string;
}

export interface SignalPeerCreate {
    type: SignalType;
    id: string;
    peerID: string;
}

export interface SignalPeerClose {
    type: SignalType;
    id: string;
    peerID: string;
}

export interface SignalPeerJoin {
    type: SignalType;
    id: string;
    peerID: string;
    sid: string;
    offer: RTCSessionDescriptionInit;
    config: JoinConfig;
}

export interface SignalPeerOffer {
    type: SignalType;
    id: string;
    peerID: string;
    offer: RTCSessionDescriptionInit;
}

export interface SignalPeerAnswer {
    type: SignalType;
    id?: string;
    peerID: string;
    offer: RTCSessionDescriptionInit;
}

export interface SignalPeerNegotiation {
    type: SignalType;
    id: string;
    peerID: string;
    desc: RTCSessionDescriptionInit;
}

export interface SignalPeerTrickle {
    type: SignalType;
    target: number;
    peerID: string;
    candidate: RTCIceCandidateInit;
}

export interface SignalRoomState extends RoomState {
    type: SignalType;
}

export interface SignalRoomSession {
    type: SignalType;
    scheduledSession: ScheduledSession;
}

export interface SignalRoomSettingsUpdate {
    type: SignalType;
    settings: RoomSettings;
}

export interface SignalSubsQueueSizeUpdate {
    type: SignalType;
    size: number;
}

export interface SignalRoomMutedUpdate {
    type: SignalType;
    muted: { [key: string]: void };
}

export interface SignalContentState {
    type: SignalType;
    contentState: ContentState;
}

export interface SignalParticipantName {
    type: SignalType;
    name: string;
}

export interface SignalParticipantCamMic {
    type: SignalType;
    camState?: CamMicState;
    micState?: CamMicState;
}

export interface SignalParticipantCam {
    type: SignalType;
    state: CamMicState;
    mode: CamMode;
}

export interface SignalParticipantMic {
    type: SignalType;
    state: CamMicState;
}

export interface SignalParticipantUpdate {
    type: SignalType;
    participant: Participant;
}

export interface SignalParticipantsUpdate {
    type: SignalType;
    participants: Participant[];
}

export interface SignalParticipantUnregister {
    type: SignalType;
    participantID: string;
}

export interface SignalParticipantAuth {
    type: SignalType;
    userID: string;
    accessToken: string;
    name: string;
    avatarURL: string;
}

export interface SignalParticipantMute {
    type: SignalType;
    mutedID: string;
    muterID: string;
    mute: boolean;
}

export interface SignalContentStreamMetadata extends ContentStreamMetadata {
    type: SignalType;
}

export interface SignalPublisherWebRTCUpdate {
    type: SignalType;
    update: PublisherWebRTCMetricsUpdate;
}

export interface SignalSubscriberWebRTCUpdate {
    type: SignalType;
    update: SubscriberWebRTCMetricsUpdate;
}

export interface SignalVoteCast extends Vote {
    type: SignalType;
}

export interface SignalVoteState extends VoteState {
    type: SignalType;
}

export interface SignalVoteStreakState extends VoteStreakState {
    type: SignalType;
}

export interface SignalStage {
    members: { [key: string]: number };
    pendingRequests: { [key: string]: number };
    pendingInvites: { [key: string]: number };
}

export interface SignalStageUpdate {
    type: SignalType;
    stage: SignalStage;
}

export interface SignalStageInvite {
    type: SignalType;
    inviteeID: string;
    revoke: boolean;
}

export interface SignalStageRSVP {
    type: SignalType;
    accepted: boolean;
}

export interface SignalStageKick {
    type: SignalType;
    kickedID: string;
    kickerID: string;
}

export interface SignalStageRequest {
    type: SignalType;
    revoke: boolean;
}

export interface SignalStageAccept {
    type: SignalType;
    requesterID: string;
    accepted: boolean;
}

export interface SignalQualityIndicator {
    type: SignalType;
    indicatorType: QualityIndicatorType;
    active: boolean;
}
